









































import { ShopInformation } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import informationService from "@/service/shopInformationService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import shopInformationService from "@/service/shopInformationService";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class InformationEditDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ShopInformation = informationService.defaultShopInformation;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: ShopInformation): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    shopInformationService
      .deleteShopInformation(this.item)
      .then((result) => {
        this.isProgressing = false;
        this.notifySuccess(result as ShopInformation);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: ShopInformation): ShopInformation {
    return deleted;
  }
}
