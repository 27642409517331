
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

@Component
export default class MixinInformationEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //タイトル
      title: { required },
      //本文
      content: { required },
    },
    publicationDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationEndDate", this, parentVm);
        return moment(value).isBefore(moment(targetDate));
      },
    },
    publicationEndDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationDate", this, parentVm);
        return moment(value).isAfter(moment(targetDate));
      },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * タイトルの入力エラーメッセージを返します.
   */
  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.title?.$dirty) return errors;
    !this.$v.item.title?.required && errors.push("タイトルを入力してください.");
    return errors;
  }
  /**
   * 本文の入力エラーメッセージを返します.
   */
  get contentErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.content?.$dirty) return errors;
    !this.$v.item.content?.required && errors.push("本文を入力してください.");
    return errors;
  }
  /**
   * 公開開始日の入力エラーメッセージを返します.
   */
  get publicationDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationDate.$dirty) return errors;
    !this.$v.publicationDate.validDate &&
      errors.push("公開終了日より前の日時を指定してください.");
    return errors;
  }
  /**
   * 公開終了日の入力エラーメッセージを返します.
   */
  get publicationEndDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationEndDate.$dirty) return errors;
    !this.$v.publicationEndDate.validDate &&
      errors.push("公開開始日より後の日時を指定してください.");
    return errors;
  }
}
