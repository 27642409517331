










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import InformationList from "@/components/organisms/information/InformationList.vue";

export default {
  components: {
    SubPageTemplate,
    InformationList,
  },
};
