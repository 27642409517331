var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20, 50, 100, -1] },"no-data-text":"お知らせは登録されていません。","loading":_vm.loadingDataGrid,"loading-text":"お知らせを読み込んでいます..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.openCreateDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" お知らせを追加 ")],1)],1),_c('InformationEditDialog',{ref:"editDialog",on:{"onCreateSuccess":_vm.onCreateSuccess,"onUpdateSuccess":_vm.onUpdateSuccess}}),_c('InformationDeleteDialog',{ref:"deleteDialog",on:{"onSuccess":_vm.onDeleteSuccess}})]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
    )),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.editAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.deleteAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }