import { ShopInformation } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";
import moment from "moment";

/**
 * ショップお知らせに関する機能を提供します.
 */
class ShopInformationService {
  /**
   * 初期状態のショップお知らせ
   */
  public get defaultShopInformation(): ShopInformation {
    return {
      guid: "",
      title: "",
      content: "",
      publicationDate: moment().format(),
      publicationEndDate: moment().add(7, "days").format(),
      createdAt: "",
      updatedAt: "",
    } as ShopInformation;
  }

  /**
   * すべてのショップお知らせを取得して返します.
   * @returns すべてのショップお知らせ
   */
  public async allShopInformations() {
    const response = await sdk.shopInformations();
    if (response?.shopInformations) {
      return response.shopInformations as ShopInformation[];
    }
    return [] as ShopInformation[];
  }

  /**
   * 指定したGUIDに一致するショップお知らせを取得して返します.
   * @param guid ショップお知らせのGUID
   * @returns ショップお知らせ
   */
  public async getShopInformation(guid: string) {
    const response = await sdk.shopInformation({
      shopInformationGuid: guid,
    });
    return response.shopInformation as ShopInformation;
  }

  /**
   * ショップお知らせを登録します.
   * @param input ショップお知らせの登録情報
   * @returns 登録完了後のショップお知らせ
   */
  public async createShopInformation(input: ShopInformation) {
    try {
      const response = await sdk.createShopInformation({
        createShopInformationInput: {
          title: input.title,
          content: input.content,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
        },
      });

      if (response?.createShopInformation) {
        return response.createShopInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ショップお知らせを更新します.
   * @param input ショップお知らせの更新情報
   * @returns 更新完了後のショップお知らせ
   */
  public async updateShopInformation(input: ShopInformation) {
    try {
      const response = await sdk.updateShopInformation({
        updateShopInformationInput: {
          guid: input.guid,
          title: input.title,
          content: input.content,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
        },
      });

      if (response?.updateShopInformation) {
        return response.updateShopInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ショップお知らせを削除します.
   * @param input ショップお知らせの削除情報
   * @returns 削除完了後のショップお知らせ
   */
  public async deleteShopInformation(input: ShopInformation) {
    try {
      const response = await sdk.deleteShopInformation({
        deleteShopInformationInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteShopInformation) {
        return response.deleteShopInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ShopInformationService();
